import React from "react";

const Subscribe = () => {
  return (
    <div className="subscribe_box">
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          placeholder="Enter your email address"
        />
      </div>
      <div className="form-group">
        <button type="submit">Subscribe</button>
      </div>
    </div>
  );
};

export default Subscribe;
