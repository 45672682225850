import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

import TestimonialImage from "../../assets/images/testimonial_img.PNG";

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="testimonial_arrow tesimonial_next"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <MdKeyboardArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="testimonial_arrow tesimonial_prev"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <MdKeyboardArrowLeft />
    </div>
  );
}

const TestimonialSlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="testimonial_slider">
      <div className="container">
        <div className="testimonial_slider_wrapper">
          <Slider {...settings}>
            <div>
              <div className="testimonail_slider_image">
                <img src={TestimonialImage} alt="" />
              </div>
            </div>
            <div>
              <div className="testimonail_slider_image">
                <img src={TestimonialImage} alt="" />
              </div>
            </div>
            <div>
              <div className="testimonail_slider_image">
                <img src={TestimonialImage} alt="" />
              </div>
            </div>
            <div>
              <div className="testimonail_slider_image">
                <img src={TestimonialImage} alt="" />
              </div>
            </div>
            <div>
              <div className="testimonail_slider_image">
                <img src={TestimonialImage} alt="" />
              </div>
            </div>
            <div>
              <div className="testimonail_slider_image">
                <img src={TestimonialImage} alt="" />
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;
