import React from "react";

import Info from "./Info";
import Form from "./Form";
import Subscribe from "./Subscribe";

const Contact = () => {
  return (
    <div className="contact_content">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <Info />
            <Subscribe />
          </div>
          <div className="col-lg-7">
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
