import React from "react";

// import Clients from "./Clients";
import Slider from "./Slider";

const TESTIMONIALS = () => {
  return (
    <>
      <div className="testimonials_content">
        <div className="container">
          <div className="section_title">
            <h3>WHAT PEOPLE HAVE TO SAY ABOUT US.</h3>
          </div>
          <ul>
            <li>
              <p>
                You made it so simple. My new site is so much faster and easier
                to work with than my old site. I just choose the page, make the
                change and click save. Thanks, guys!
              </p>
              <span>-Robert</span>
            </li>
            <li>
              <p>
                You made it so simple. My new site is so much faster and easier
                to work with than my old site. I just choose the page, make the
                change and click save. Thanks, guys!
              </p>
              <span>-Robert</span>
            </li>
            <li>
              <p>
                You made it so simple. My new site is so much faster and easier
                to work with than my old site. I just choose the page, make the
                change and click save. Thanks, guys!
              </p>
              <span>-Robert</span>
            </li>
          </ul>
        </div>
      </div>
      {/* <Clients /> */}
      <Slider />
    </>
  );
};

export default TESTIMONIALS;
