import React from "react";

import Tabs from "../../components/Tabs";
import Featured from "./Featured";

const Home = () => {
  return (
    <div className="home_content_wrapper">
      {/* <Tabs /> */}
      <Featured />
    </div>
  );
};

export default Home;
