import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import logo_one from "../assets/images/logo.png";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const handleMenuBar = () => {
    setShowMenu(!showMenu);
  };
  return (
    <header className="header main_header">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <Link className="navbar-brand" to="/">
            <img src={logo_one} alt="logo" className="logo_one" />
            {/*<img src={logo_one} alt="logo" /> */}
          </Link>
          <button
            className={`${showMenu ? "active" : ""} navbar-toggler`}
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExample04"
            aria-controls="navbarsExample04"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleMenuBar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`${showMenu ? "showMenu" : ""} collapse navbar-collapse`}
            id="navbarsExample04"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/" exact={true} onClick={handleMenuBar}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about-us" onClick={handleMenuBar}>
                  ABOUT US
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/our-services" onClick={handleMenuBar}>
                  SERVICES
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/gallery" onClick={handleMenuBar}>
                  MEDIA
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/testimonials" onClick={handleMenuBar}>
                  TESTIMONIALS
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact-us" onClick={handleMenuBar}>
                  CONTACT US
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
