import React from "react";

const Services = () => {
  return (
    <div className="services_content">
      <div className="container">
        <ul className="list">
          <li>
            Project Studies
            <ul>
              <li>Feasibility Studies</li>
              <li>Design Studies</li>
            </ul>
          </li>
          <li>
            Design and Contract Documents
            <ul>
              <li>Design for all Architectural Works</li>
              <li> Structural Design</li>
              <li> Building Service Design</li>
              <li> Designs of Infrastructure Works</li>
              <li> Interior Design</li>
              <li> Landscape Architecture</li>
              <li> Industrial Architecture</li>
              <li>
                Cost Estimates and Preparation of Construction Drawings and
                Documents
              </li>
              <li>
                {" "}
                Contract Negotiation and Preparation of Contract Documents
              </li>
            </ul>
          </li>
          <li>
            Executive Services
            <ul>
              <li>Contract Administration</li>
              <li>Construction Supervision</li>
            </ul>
          </li>

          <li>
            Survey & Valuation
            <ul>
              <li>Surveying </li>
              <li> Property Valuation / Assessment</li>
            </ul>
          </li>
          <li>
            Development
            <ul>
              <li>Development Plans </li>
              <li>Development of Properties </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Services;
