import React from "react";

const Form = () => {
  return (
    <div className="contact_form">
      <form>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="form-group">
              <label>Name</label>
              <input className="form-control" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Email</label>
              <input className="form-control" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Phone No</label>
              <input className="form-control" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Subject</label>
              <input className="form-control" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Message</label>
              <textarea className="form-control" />
            </div>
            <div className="form-group">
              <button type="submit" className="submit-btn">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
