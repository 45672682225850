import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

import Header from "./components/Header";
import Footer from "./components/Footer";

import HomePage from "./screens/Home";
import AboutPage from "./screens/About";
import ServicePage from "./screens/services";
import WorkDetailPage from "./screens/Home/Detail";
import MediaPage from "./screens/Media";
import TestimonialsPage from "./screens/Testimonials";
import ContactPage from "./screens/Contact";

const App = () => {
  return (
    <Router>
      <Header />
      <ScrollToTop>
        <Switch>
          <Route path="/contact-us" exact component={ContactPage} />
          <Route path="/about-us" exact component={AboutPage} />
          <Route path="/our-services" exact component={ServicePage} />
          <Route path="/gallery" exact component={MediaPage} />
          <Route path="/testimonials" exact component={TestimonialsPage} />
          <Route path="/our-work/:id" exact component={WorkDetailPage} />
          <Route path="/" exact component={HomePage} />
        </Switch>
      </ScrollToTop>
      <Footer />
    </Router>
  );
};

export default App;
