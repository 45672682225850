import React from "react";

import Info from "./Info";
import Team from "./Team";

const About = () => {
  return (
    <>
      <Info />
      <Team />
    </>
  );
};

export default About;
