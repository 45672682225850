import React from "react";
import { BiMap, BiPhoneCall, BiEnvelope } from "react-icons/bi";

const Info = () => {
  return (
    <>
      <div className="section_title">
        <h3 style={{ marginBottom: 40 }}>We would love to hear from you.</h3>
      </div>
      <div className="contact_info">
        <ul>
          <li>
            <BiMap />
            MLK Building, Kandevasthan, Kopundole
          </li>
          <li>
            <BiPhoneCall />
            +01-5521279
          </li>
          <li>
            <BiEnvelope />
            mlkayastha@gmail.com
          </li>
        </ul>
      </div>
    </>
  );
};

export default Info;
