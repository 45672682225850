import React from "react";

import team_one from "../../assets/images/team_one.jpg";

const Team = () => {
  return (
    <div className="team_container">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="team_item">
              <figure>
                <img src={team_one} alt="" />
              </figure>
              <p>
                Ar. Matsyendra Lal Kayastha
                <br />
                Chairman/ Principal Architect
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="team_item">
              <figure>
                <img src={team_one} alt="" />
              </figure>
              <p>
                Ar. Matsyendra Lal Kayastha
                <br />
                Chairman/ Principal Architect
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="team_item">
              <figure>
                <img src={team_one} alt="" />
              </figure>
              <p>
                Ar. Matsyendra Lal Kayastha
                <br />
                Chairman/ Principal Architect
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="team_item">
              <figure>
                <img src={team_one} alt="" />
              </figure>
              <p>
                Ar. Matsyendra Lal Kayastha
                <br />
                Chairman/ Principal Architect
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
