import React from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import banner_one from "../../assets/images/banner_one.jpg";
import gallery_five from "../../assets/images/gallery_five.jpg";
import gallery_four from "../../assets/images/gallery_four.jpg";
import gallery_three from "../../assets/images/gallery_three.jpg";
import gallery_two from "../../assets/images/gallery_two.jpg";
import house_one from "../../assets/images/house_one.jpg";
import house_two from "../../assets/images/house_two.jpg";
import house_three from "../../assets/images/house_three.jpg";
import house_four from "../../assets/images/house_four.jpg";
import house_five from "../../assets/images/house_five.png";
import house_six from "../../assets/images/house_six.jpg";
import house_seven from "../../assets/images/house_seven.jpg";
import house_eight from "../../assets/images/house_eight.jpg";

const Featured = () => {
  return (
    <div className="home_product_wrapper">
      <div className="container">
        <Tabs>
          <TabList>
            <Tab>Featured</Tab>
            <Tab>All Works</Tab>
          </TabList>
          <TabPanel>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={banner_one} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={gallery_five} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={gallery_four} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={gallery_three} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={gallery_two} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={gallery_three} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={gallery_five} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={gallery_four} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={gallery_three} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={house_one} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={house_two} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={house_three} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={house_four} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={house_five} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={house_six} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={house_seven} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={house_eight} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <Link to="/our-work/1">
                    <figure>
                      <img src={house_one} alt="" />
                    </figure>
                    <div className="product_item_content">
                      <h4>JAWALAKHEL HANDICRAFT CENTER</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <figure>
                    <img src={house_two} alt="" />
                  </figure>
                  <div className="product_item_content">
                    <h4>
                      <Link to="/our-work/1">JAWALAKHEL HANDICRAFT CENTER</Link>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <figure>
                    <img src={house_three} alt="" />
                  </figure>
                  <div className="product_item_content">
                    <h4>
                      <Link to="/our-work/1">JAWALAKHEL HANDICRAFT CENTER</Link>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="product_item">
                  <figure>
                    <img src={house_four} alt="" />
                  </figure>
                  <div className="product_item_content">
                    <h4>
                      <Link to="/our-work/1">JAWALAKHEL HANDICRAFT CENTER</Link>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Featured;
