import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { FaImage, FaAngleRight, FaAngleLeft } from "react-icons/fa";

import banner_one from "../../assets/images/banner_one.jpg";
import gallery_five from "../../assets/images/gallery_five.jpg";
import gallery_four from "../../assets/images/gallery_four.jpg";
import gallery_three from "../../assets/images/gallery_three.jpg";
import gallery_two from "../../assets/images/gallery_two.jpg";

const images = [
  banner_one,
  gallery_five,
  gallery_four,
  gallery_three,
  gallery_two
];

const Media = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  return (
    <>
      <div className="media_content">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={banner_one} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_five} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_four} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_three} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_two} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={banner_one} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_five} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_four} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_three} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_two} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={banner_one} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_five} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_four} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_three} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_two} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="gallery_item">
                <img src={gallery_two} alt="" />
                <button
                  type="button"
                  onClick={() => setIsOpen({ isOpen: true })}
                >
                  <FaImage />
                </button>
              </div>
            </div>
          </div>
          <div className="next_prev_icon">
            <ul>
              <li>
                <FaAngleLeft />
              </li>
              <li>
                <FaAngleRight />
              </li>
            </ul>
          </div>
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen({ isOpen: false })}
          onMovePrevRequest={() =>
            setPhotoIndex({
              photoIndex: (photoIndex + images.length - 1) % images.length
            })
          }
          onMoveNextRequest={() =>
            setPhotoIndex({
              photoIndex: (photoIndex + 1) % images.length
            })
          }
        />
      )}
    </>
  );
};

export default Media;
