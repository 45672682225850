import React from "react";

import gallery_three from "../../assets/images/gallery_three.jpg";

const Info = () => {
  return (
    <div className="innerpage_content">
      <div className="container">
        <div className="about_img">
          <img src={gallery_three} alt="" />
        </div>
        <div className="about_content">
          <p>
            Mr. & Mrs. M.L. Kayastha & Associates Pvt. Ltd., established as Mr.
            & Mrs. M.L. Kayastha & Associates in 1976, is among the pioneer
            full-time firm of Consulting Architects, Engineers, Designers and
            Developers of Nepal.
          </p>
          <p>
            From the last four decades, the firm has been working full time with
            Experienced and Qualified Architects, Engineers, Designers and
            Technical Manpower, Equipments and Office Facilities. The firm has
            been dedicated towards the development of Nepal through
            Architectural and Engineering Services. Over the years, the firm has
            had numerous opportunities to work with various Departments of the
            Government of Nepal, Tribhuvan and Mahendra Sanskrit Universities,
            USAID, UNDP, The World Bank and Asian Development Bank, NABIL Bank,
            British Embassy, Kathmandu Metropolitan City Office,Corporations,
            Paropakar Maternity Hospital, B & B Hospital, NGO, INGO and other
            private agencies, after being selected through Pre-qualification of
            Consultants, Technical Proposals and Design Competitions.
          </p>
          <p>
            The Principals are founder Life Members of Society of Nepalese
            Architects (SONA), the professional society of the Nepalese
            Architects. Ar. M.L. Kayastha was also President of the Society of
            Nepalese Architects for 1995-97
          </p>
        </div>
      </div>
    </div>
  );
};

export default Info;
