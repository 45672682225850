import React from "react";
import { FaFacebook, FaTwitter } from "react-icons/fa";

import banner_one from "../../assets/images/banner_one.jpg";
import gallery_five from "../../assets/images/gallery_five.jpg";
import gallery_four from "../../assets/images/gallery_four.jpg";
import gallery_three from "../../assets/images/gallery_three.jpg";

const Detail = () => {
  return (
    <div className="work_content_detail">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="work_detail">
              <div className="work_detail_title">
                <h3>JAWALAKHEL HANDICRAFT CENTER</h3>
                <span>Ekantakuna, Jawalakhel</span>
              </div>
              <div className="client_completed">
                <p style={{ paddingTop: 30, paddingBottom: 10 }}>
                  Client:
                  <br /> Completed:
                </p>
              </div>
              <div className="work_detail_description">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
              <div className="work_social_media">
                <ul>
                  <li>Share:</li>
                  <li>
                    <a href="#" target="_blank">
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <FaTwitter />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="work_img">
              <ul>
                <li>
                  <img src={banner_one} alt="" />
                </li>
                <li>
                  <img src={gallery_five} alt="" />
                </li>
                <li>
                  <img src={gallery_four} alt="" />
                </li>
                <li>
                  <img src={gallery_three} alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
